/*=========================================================================================
 File Name: dashboard-fitness.js
 Description: Dahsboard fitness js
 ----------------------------------------------------------------------------------------
 Item Name: Robust - Responsive Admin Theme
 Version: 1.0
 Author: GeeksLabs
 Author URL: http://www.themeforest.net/user/geekslabs
 ==========================================================================================*/
$(document).ready(function () {
    var percentline = $('#graf-line').attr('data-value');
    var percentlinenumber = parseInt(percentline);
    var resultpercent = 550 - (percentlinenumber / 100 * 550);
    //alert(resultpercent);
    $('#graf-line').css("padding-bottom", resultpercent + "px");
});


$(".dashboard-link").click(function () {
    $(this).addClass('active-link');
    $('#dashboard').css('display', 'block');
    $('#params').css('display', 'none');
    $('#otherCheckups').css('display', 'block');
});

$(".params-link").click(function () {
    $(this).addClass('active-link');
    $('#params').css('display', 'block');
    $('#dashboard').css('display', 'none');
    $('#otherCheckups').css('display', 'none');
});

$("div.open-graph.enabled").click(function () {
    var $element = $(this);
    if ($element.closest('.param').find('.graph-space').hasClass('hide')) {
        var parameter = $element.data('parameter-id');
        $element.closest('.param').find('.spinner-charging').removeClass('hide');
        
        drawHistoryGraph($element);
    } else {
        $element.closest('.param').find('.graph-space').toggleClass('hide');
    }
});


var rtl = false;
if ($('html').data('textdirection') == 'RTL')
    rtl = true;
$(".knob").knob({
    rtl: rtl,
    draw: function () {
        var ele = this.$;
        var style = ele.attr('style');
        var fontSize = parseInt(ele.css('font-size'), 10);
        var updateFontSize = Math.ceil(fontSize * 1.65);
        style = style.replace("bold", "normal");
        style = style + "font-size: " + updateFontSize + "px;";
        var icon = ele.attr('data-knob-icon');
        ele.next('.knob-center-icon').remove();
        $('<i class="knob-center-icon ' + icon + '"></i>').insertAfter(ele);

        // "tron" case
        if (this.$.data('skin') == 'tron') {

            this.cursorExt = 0.3;

            var a = this.arc(this.cv), // Arc
                    pa, // Previous arc
                    r = 1;

            this.g.lineWidth = this.lineWidth;

            if (this.o.displayPrevious) {
                pa = this.arc(this.v);
                this.g.beginPath();
                this.g.strokeStyle = this.pColor;
                this.g.arc(this.xy, this.xy, this.radius - this.lineWidth, pa.s, pa.e, pa.d);
                this.g.stroke();
            }

            this.g.beginPath();
            this.g.strokeStyle = r ? this.o.fgColor : this.fgColor;
            this.g.arc(this.xy, this.xy, this.radius - this.lineWidth, a.s, a.e, a.d);
            this.g.stroke();

            this.g.lineWidth = 2;
            this.g.beginPath();
            this.g.strokeStyle = this.o.fgColor;
            this.g.arc(this.xy, this.xy, this.radius - this.lineWidth + 1 + this.lineWidth * 2 / 3, 0, 2 * Math.PI, false);
            this.g.stroke();

            return false;
        }
    }
});
