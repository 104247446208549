var drawHistoryGraph = function drawHistoryGraph($element) {
    var dummy = $('<div class="bg-' + $element.data('category') + '"></div>').appendTo('body');
    var backgroundColor = dummy.css('background-color');
    dummy.remove();

    $.post($element.data('target'), {
        id: $element.data('parameter-id'),
        clientId: $element.data('client-id'),
        _token: $('meta[name="csrf-token"]').attr('content')
    }).done(function (datas) {

        var chartOptions = {
            responsive: true,
            maintainAspectRatio: false,
            tooltips: {
                callbacks: {
                    label: function (item) {
                        var date = new Date(item.xLabel);
                        var dateString = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
                        return dateString + ': ' + item.yLabel.toFixed(1);
                    }
                }
            },
            legend: {
                display: false,
                position: 'bottom'
            },
            hover: {
                mode: 'label'
            },
            scales: {
                xAxes: [{
                    ticks: {
                        callback: function (value, index, values) {
                            var date = new Date(value);
                            return date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
                        }
                    }
                }],
                yAxes: [{
                    display: true,
                    gridLines: {
                        color: "#f3f3f3",
                        drawTicks: false
                    },
                    scaleLabel: {
                        display: true,
                        labelString: 'Valore' + ($element.data('units') != '' ? '[' + $element.data('units') + ']' : '')
                    }
                }]
            },
            title: {
                display: false,
                text: 'Params'
            }
        };

        data = JSON.parse(datas);
        
        data.forEach(function (item, index) {
            // Chart Body
            var chartData = {
                datasets: [{
                    label: 'WHR',
                    data: item.data,
                    lineTension: 0,
                    fill: true,
                    borderColor: backgroundColor,
                    pointBorderColor: backgroundColor,
                    pointBackgroundColor: "#FFF",
                    pointBorderWidth: 1,
                    pointHoverBorderWidth: 3,
                    pointRadius: 4
                }]
            };

            var configChart = {
                type: 'scatter',
                options: chartOptions,
                data: chartData
            };


            $element.closest('.param').find('.spinner-charging').addClass('hide');
            $element.closest('.param').find('.graph-space').removeClass('hide');
            var $lineChart = new Chart($element.closest('.param').find(".line-chart-" + $element.data('parameter-id')), configChart);
        });
        

    });
}

$(document).ready(function () {
    $("div.open-graph.enabled.always-opened").each(function () {
        drawHistoryGraph($(this));
    });

});